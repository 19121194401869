.float {
    position: fixed;
    width: 51px;
    height: 51px;
    bottom: 24px;
    right: 19px;
    background-color: #25d366;
    color: #fff;
    border-radius: 33px;
    text-align: center;
    font-size: 30px;
    z-index: 1000;
  }
  .float:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #fff;
  }
  
  .my-float {
    margin-top: 11px;
  }

  #circle span {
    position: fixed;
    width: 10px;
    height: 40px;
    bottom: 49px;
    right: 40px;
    text-align: center;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
    color: #000;
    transform-origin: bottom center;
    z-index: 10;
  }

  .centrado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* -webkit-text-stroke: 1px rgb(0, 0, 0); */
  }